.container {
  position: relative;
}

.selectedValues {
  box-sizing: border-box;
  padding: 2.5px 10px 2.5px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  justify-content: space-between;
}

.selectedValues:hover:not(:focus) {
  border: 1px solid #000;
}

.selectedValues:focus {
  outline: 2px solid #238884;
  outline-offset: -2px;
}

.optionsMenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99900;
  background-color: #fff;
  border: 1px solid #ccc;

  width: 100%;
  max-width: 364px;
  overflow-y: auto;
  margin-top: 4px;
  border-radius: 4px;
}

.optionsList {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-height: 240px;
}

.optionsList label {
  display: flex;
  align-items: center;
  gap: 9px;
}

.optionsList label span {
  padding: 0;
}
.optionButtons {
  display: flex;
  align-items: center;
  margin: 24px 0 16px;
  gap: 4px;
}

.optionButtons button {
  border: none;
  background: none;
  color: #238884;
  text-transform: uppercase;
  font-size: 14px;
  /* font-weight: bold; */
  padding: 0;
  cursor: pointer;
}

.separator {
  height: 2px;
  width: 4px;
  background: #238884;
}
