.inputOption {
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
  }
  margin: 0;
  height: 40px;
}
