.table-detail-patient {
  border-collapse: separate;
  // border-spacing: 16px 0;
  width: 100%;
  // font-family: Roboto;

  th {
    color: var(--Text-Secondary, rgba(0, 0, 0, 0.6));
    font-feature-settings: 'liga' off, 'clig' off;

    // font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
    text-align: start;
    margin-right: 16px;
  }

  td {
    color: var(--Text-Primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'liga' off, 'clig' off;
    margin-right: 16px;
    // font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 175%;
    letter-spacing: 0.15px;
  }
}
